.scrollUpButton {
    //@include defaultButton();
    border: none;
    background: inherit;

    &:focus {
        outline: none;
    }

    //@include easeTransition();
    //@include transition(all 0.3s ease);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    position: fixed;
    right: -100px;
    bottom: 50px;
    z-index: 1;

    width: 50px;
    height: 50px;

    border: 2px solid #000000;
    background-color: #ffffff;

    cursor: pointer;
    font-size: 20px;

    img {
        height: 50px;
    }

    &:hover {
        filter: opacity(0.8);
    }
}

.transition {
    right: 20px;
}

.children {
    bottom: 47px;
    right: -30px;

    width: 108px;

    font-size: 16px;
}
