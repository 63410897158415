.actionButton {
    border: 1px solid #000000 !important;

    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        height: 40px;

        &:hover {
            filter: invert(100%);
        }
    }

    &:focus {
        img {
            filter: invert(100%) !important;
        }
    }
}

.disabledImg {
    opacity: 0.2;

    &:hover {
        filter: invert(0%) !important;
    }
}

.small {
    width: 25px;
    height: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 25px;
        height: 25px;
    }
}
