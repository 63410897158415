.topNavBar {
    height: 40px;
}

.headerLabel {
    color: #ffffff;
    font-weight: bold;
    line-height: 20px;
    align-self: center;
}

.middleNavBarSticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.middleNavBarOrigin {
    position: relative;
    z-index: 999;
}
