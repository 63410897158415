@import "tools/styles/helpers";

html {
    position: relative;
    min-height: 100%;
    font-size: 14px;
    scrollbar-gutter: stable;
}

body {
    @include overflowX(hidden);

    min-height: 100vh;

    #root {
        display: flex;
        flex-direction: column;
        height: 100vh;
        scrollbar-gutter: stable;
    }
}

button {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    outline: 0;
}

kbd {
    color: #242729;
    font-size: 11px;
    text-shadow: 0 1px 0 #ffffff;
    padding: 0.1em 0.6em;
    background-color: #e1e3e5;
    border: 1px solid #adb3b8;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 2px #ffffff inset;
    white-space: nowrap;
}

input {
    &::-ms-clear {
        display: none;
    }

    &::-webkit-clear-button {
        display: none;
    }
}

date-input-polyfill {
    z-index: 10000 !important;
}

.tooltip {
    z-index: 99999999;
}

.tooltip-wide {
    .tooltip {
        .bottom {
            white-space: pre-wrap;
            max-width: none;
            background-color: #f16e00;
            color: #ffffff;
        }
    }
}

.tooltip-inner {
    white-space: pre-wrap;
    max-width: none;
    background-color: #f16e00;
    color: #ffffff;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.hidden-overflow-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.badge {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

::selection {
    background: #f16e00;
    color: #ffffff;
}

.mb-minus-1-px {
    margin-bottom: -1px;
}

.mb-minus-2 {
    margin-bottom: -20px;
}

.mt-minus-1 {
    margin-top: -10px;
}

.mt-minus-6 {
    margin-top: -60px;
}

.opacity-25 {
    opacity: 0.25;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-75 {
    opacity: 0.75;
}

.hover-bg-light {
    &:hover {
        background-color: #dddddd;
    }
}

.Toastify__toast {
    background: none !important;
}
