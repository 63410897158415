.dropDown {
    z-index: 1;
}

.dropDownFilters {
    @extend .dropDown;

    width: 300px;
    right: -10px;
    top: 40px;

    .item {
        &:not(:first-child) {
            margin-bottom: 10px;
        }
    }
}

.dropDownButtons {
    @extend .dropDown;

    .item {
        margin-bottom: -2px;
    }
}

.dropDownButtonsOne {
    @extend .dropDownButtons;

    right: -45px;
    top: 0;
}

.dropDownButtonsTwo {
    @extend .dropDownButtons;

    right: -45px;
    top: -19px;
}

.dropDownButtonsThreeAndMore {
    @extend .dropDownButtons;

    right: -45px;
    top: -38px;
}
