.indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    z-index: 1;

    background: #ffffff;
    border: 1px solid #cccccc;

    padding: 5px;
    margin-top: 5px;
}

.tooltipLeft {
    @extend .tooltip;

    top: -100%;
    left: -100%;
    width: fit-content;
}
