.orangeAutoSuggest {
  position: relative;

  .inputContainer {
    position: relative;

    input {
      width: 100%;
      height: 40px;
    }
    .inputIcon {
      width: 15px;
      height: 15px;
    }
  }

  .inputError {
    position: absolute;
    top: 95%;
    width: 100%;
    left: 0;

    font-size: 14px;
    color: red;
    z-index: 1;
  }

  .inputMaskContainer {
    height: 40px;
  }

  .suggestions {
    position: absolute;

    left: 0;
    right: 0;

    z-index: 1001;
    background: #ffffff;
    border: 1px solid #000000;

    margin-top: -1px;

    .suggestion {
      cursor: pointer;

      padding: 5px 10px 5px 10px;
      min-height: 20px;

      &:hover {
        color: #ffffff;
        background: #f16e00;
      }
    }
  }
}
