.confirmationModal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    padding: 10px;
    width: 50%;

    min-height: 50%;
    max-height: 50%;
    margin-top: 15%;
    scrollbar-width: none;
    overflow-x: hidden;
    overflow-y: scroll;

    height: fit-content;
    background-color: #ffffff;
    z-index: 100000;
}

.overlayInConfirmModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
