.modal {
    padding: 10px;
    width: 70%;

    min-height: 50vh;
    max-height: 70vh;
    margin-top: 15vh;

    overflow-x: hidden;
    overflow-y: scroll;

    height: fit-content;
    background-color: #ffffff;
    z-index: 100000;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
