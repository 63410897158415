.loadingPanel {
    position: fixed;
    bottom: 0;
    z-index: 1;
    right: 100px;
    width: 300px;
    background: #ffffff;
}

.top {
    background: #000000;
    color: #ffffff;
    cursor: default;
}

.topButton {
    //@include noSelectText();
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    cursor: pointer;
    margin-left: 10px;
}

.items {
    height: 200px;
    overflow: auto;
}

.item {
    .itemText {
        margin-top: 4px;
        cursor: default;
    }
}
