.logoContainer {
    padding: 0;
    margin-left: 0;

    .icon {
        width: auto;
        height: 60px;
    }
    .iconSticky {
        width: auto;
        height: 60px;
    }
}
