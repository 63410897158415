.boostedButton {
  height: 42px;
  min-width: 165px;

  .image {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    img {
      background: #ffffff;
    }
  }
}
