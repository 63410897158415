.tableEditorUnset {
    position: fixed;
    top: 24vh;
    right: 0;
    z-index: 500;
}

.tableEditorUnsetButton {
    > input {
        transform: rotate(270deg);
        margin-top: 225px;
        margin-right: -13px;
    }
}

.tableEditorUnsetContent {
    overflow: auto;
    max-width: 500px;
    max-height: 500px;
    min-height: 500px;
}

.tableEditorEmptyUnset {
    width: 200px;
    height: 50px;
}

.droppableContainerWhite {
    background: #ffffff;
}

.droppableContainerBlue {
    background: #527edb;
}
