
.totalFiltersRow {
  background-color: #EEEEEE;
  min-height: 40px;

  .foundField {
    color: #666666;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
  }

  .clearButton {
    color: #000000;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    :active {
      color: #f16e00;
    }

    img {
      width: 10px;
      height: 10px;

      content: url("./media/remove-icon.svg");
    }
  }
}