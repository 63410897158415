.sidebar {
  $marginTop: 27.4vh;

  max-width: 305px;

  margin-top: #{$marginTop};
  max-height: calc(100vh - 27.4vh);

  .sidebarLabel {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  .total {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: #cccccc;

    padding: 10px 25px 10px 0;
  }

  .body {
    overflow: scroll;
    -ms-overflow-x: hidden;
    overflow-x: hidden;
  }
}