.tableEditorEmptyLine {
    height: 50px;
}

.droppableContainerWhite {
    background: #ffffff;
}

.droppableContainerBlue {
    background: #527edb;
}
