.containerInNotificationsSlider {
    padding: 0;
    width: 100%;
    max-width: 100%;
    background-color: lightgrey;
    clear: both;
    float: left;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    animation-duration: 2s;
    animation-name: slidein;
}

.notificationsMoreinWrapper{
    float: right;
    animation-name: appearance;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 3s;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 100%;
        opacity: 0;
    }
    to {
        margin-left: 0%;
        width: 100%;
        opacity: 1;
    }
}

@keyframes appearance {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modalInNotificationsSlider {
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 0.5s;
}

@keyframes modalOpening {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}