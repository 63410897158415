.suggestions {

  .suggestion {
    padding-top: 10px;
    padding-bottom: 10px;

    input {
      min-width: 20px;
      min-height: 20px;
    }

    .label {
      max-width: 242px;

      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
}